<template>
  <div>
    <div class="header-container">
      <div class="header-wrapper">
        <div class="flex-align">
          <img src="static/img/ic_logo.svg" @click="clickLogo">
          <!-- step -->
          <div class="flex-align" style="margin-left:72px" v-if="inStep">
            <div v-for="item in steps" :key="'step-'+item.index" class="flex-align" style="margin-right:32px">
              <div class="step-index" :class="{'step-index-selected': item.index === step}">{{ item.index }}</div>
              <div class="step-name" :class="{'step-name-selected': item.index === step}">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <div class="flex-align" v-if="$route.query.step && Number($route.query.step)<3">
          <button class="button is-gray body2-bold" style="font-weight:700;width:140px;margin-left:24px" @click="$router.back()">취소</button>
          <button class="button is-primary body2-bold" style="font-weight:700;width:140px;margin-left:16px" @click="clickDone">완료</button>
        </div>
        <div class="flex-align" v-else-if="inStep">
          <div class="sub body4 unselect" @click="clickTemp">임시저장</div>
          <button class="button is-gray body2-bold" style="font-weight:700;width:140px;margin-left:24px" @click="clickPrev">이전</button>
          <button class="button is-primary body2-bold" style="font-weight:700;width:140px;margin-left:16px" @click="clickNext">다음</button>
        </div>
        <div class="flex-align" v-else-if="step===0">
          <button class="button is-gray body2-bold" style="font-weight:700;width:140px;margin-left:24px" @click="$router.back()">나가기</button>
          <button class="button is-primary body2-bold" style="font-weight:700;width:140px;margin-left:16px" @click="clickNext">다음</button>
        </div>
      </div>
    </div>
    <div class="page-wrapper position-relative" style="padding-top:95px">
      <component :is="componentName" :service="service" :step="step" :temporaryServiceId="temporaryServiceId"
                 @pageSelect="onPageSelect" :ref="componentName"
                 @next="clickNext"></component>
      <div class="exit-modal">
        <sweet-modal ref="exitModal" overlay-theme="dark" @close="cancel"
                     :width="564" :hide-close-button="true">
          <div class="subtitle4 main">해당페이지에서 나가시겠습니까?</div>
          <div class="body2 sub2" style="margin-top:8px">지금까지의 서비스 신청 내용은<br>'마이페이지 > 퀵빌더 임시저장'에서<br>확인하실 수 있습니다.
          </div>
          <div class="flex-between" style="margin-top:64px">
            <button class="button is-gray" style="flex:1;margin-right:8px" @click="$refs.exitModal.close()">취소</button>
            <button class="button is-primary" style="flex:1;margin-left:8px" @click="back">확인</button>
          </div>
        </sweet-modal>
      </div>
    </div>
  </div>
</template>
<script>
  import { SweetModal } from 'sweet-modal-vue';
  import UserAPIMixin from "../../mixins/UserAPIMixin";
  import PageMixin from "../../mixins/PageMixin";
  import SuperBuilderMixin from "../../mixins/SuperBuilderMixin";
  import Step0 from "./QuickBuilder/Step0";
  import Step1 from "./QuickBuilder/Step1";
  import Step2 from "./QuickBuilder/Step2";
  import Step3 from "./QuickBuilder/Step3";
  import Step4 from "./QuickBuilder/Step4";
  import Step5 from "./QuickBuilder/Step5";
  import StepEnd from "./QuickBuilder/StepEnd";

  export default {
    name: "SuperBuilder",
    components: {
      StepEnd,
      Step0,
      Step1,
      Step2,
      Step3,
      Step4,
      Step5,
      SweetModal
    },
    mixins: [
      UserAPIMixin,
      PageMixin,
      SuperBuilderMixin
    ],
    data() {
      return {
        exitStatus: 0,
        step: 0,
        stepEnd: 6,
        clickedBack: false,
        steps: [
          {
            index: 1,
            name: '주문 방식'
          },
          {
            index: 2,
            name: '플러그인'
          },
          {
            index: 3,
            name: '홈 화면'
          },
          {
            index: 4,
            name: '상품목록'
          },
          {
            index: 5,
            name: '상품상세'
          }
        ]
      }
    },
    beforeDestroy() {
      window.removeEventListener('popstate', this.exit);
      window.onbeforeunload = null;
      document.getElementById('ch-plugin').style.display = '';
      this.saveTempRoute();
    },
    mounted() {
      this.$nextTick(()=>{
        setTimeout(()=>{
          document.getElementById('ch-plugin').style.display = 'none';
        },1000);
        window.addEventListener('popstate', this.exit);
        window.onbeforeunload = function (e) {
          // Cancel the event
          e.preventDefault();
          // Chrome requires returnValue to be set
          e.returnValue = '나가시겠습니까?';
        };
      });
    },
    computed: {
      inStep() {
        return this.step > 0 && this.step <= this.stepEnd;
      },
      componentName() {
        return `step${this.step < this.stepEnd ? this.step : '-end'}`;
      }
    },
    methods: {
      cancel() {
        if(!this.clickedBack)
          history.pushState({ data: 'super_builder' }, '닫기 확인', this.$route.fullPath);
      },
      clickLogo() {
        this.exitStatus = 1;
        this.exit();
      },
      back() {
        this.clickedBack = true;
        this.$refs.exitModal.close();
        setTimeout(()=>{
          if(this.exitStatus===0) this.$router.back();
          else if(this.exitStatus===1) this.routerPush('/home');
        },500);
      },
      exit() {
        this.$refs.exitModal.open();
      },
      onPageSelect(res) {
        let index = this.service.pages.findIndex(page=>{ return page.name === res.name });
        if(index === -1) {
          this.service.pages.push(res);
        }
      },
      clickPrev() {
        if(this.step === 1) {
          this.$router.back();
          this.exitStatus = 0;
        } else {
          this.step--;
        }
      },
      async clickNext() {
        console.log('clickNext', this.step, this.stepEnd)
        if(this.step === 0) {
          if(this.service.service_name === '') {
            this.toast('서비스명을 먼저 입력해주세요');
            return;
          } else if(this.service.key === '') {
            this.toast('서비스 아이디를 입력해주세요');
            return;
          }
          let res = await this.request.user.post('ui/super_builder/check_service', {key: this.service.key});
          if(res.data.result) {
            this.toast('서비스 아이디를 다른 이름으로 변경해주세요');
            this.$refs[this.componentName].err = '이미 등록된 서비스 아이디가 있습니다';
            return;
          } else {
            this.$refs[this.componentName].err = undefined;
          }

          history.pushState({ data: 'super_builder' }, '닫기 확인', this.$route.fullPath);
        }
        else if(this.step === 1) {
          if(this.service.conversions.length === 0) {
            this.toast('주문방식을 선택해주세요');
            return;
          }
          await this.saveTemp(false);
        }
        else if(this.step === 2) {
          await this.saveTemp(false);
          // 플러그인 페이지 거쳐서 갈땐 퀵빌더 페이지 목록 초기화
          this.service.pages = [];
        }
        /*else if(this.step === this.stepEnd-1) {
          this.saveTempRoute();
          this.$router.push('/mypage/temporary_storage');
          return;
        }*/
        this.step++;
      },
      clickDone() {
        if(this.step === 1) {
          if(this.service.conversions.length === 0) {
            this.toast('주문방식을 선택해주세요');
            return;
          }
        }

        this.request.user.patch(`ui/super_builder/temporary/${this.temporaryServiceId}`, { service: this.service }).then(()=>{
          this.$router.replace('/mypage/temporary_storage');
        });
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .header-container
    background-color #ffffff
    align-items center
    height 95px
    position fixed
    top 0
    left 0
    width 100%
    z-index 1000
    border-bottom 1px solid $gray1

  .header-wrapper
    display flex
    align-items center
    justify-content space-between
    margin 24px

  .btn-cancel
  .btn-ok
    padding 8px 20px
    color white
    font-size 14px
    border-radius 8px
  .btn-cancel
    background-color #cacaca
  .btn-ok
    margin-left 8px
    background-color #FF6600

  .step-index
    background-color $gray3
    color $sub5
    border-radius 100%
    width 28px
    height 28px
    line-height 28px
    text-align center
    font-size 14px
  .step-index-selected
    background-color $main
    color white
  .step-name
    color $sub5
    font-weight 500
    margin-left 6px
    font-size 14px
  .step-name-selected
    color $main
  .page-wrapper
    background-color $gray3
    height 100vh
    padding-top 95px
</style>
<style lang="stylus">
  .exit-modal
    .sweet-modal.is-alert .sweet-content
      padding 76px 32px 32px 32px

</style>
